<template>
  <section class="mt-3">
    <b-overlay :show="loading" rounded="sm">
        
      <h5 class="mt-3">{{ $t("MAIL_SERVER") }}</h5>
      <div class="mt-1">
        <b-form-select
          v-model="UpdateOrganisationEmailDTO.email_provider"
          :options="providerOptions"
        ></b-form-select>
      </div>

      
      <div v-if="UpdateOrganisationEmailDTO.email_provider != 'SEFOS'">
    
        <h5 class="mt-3">{{ $t("EMAILSETTINGS.PROTOCOL_TITLE") }}</h5>
        <div class="mt-1">
          <b-form-select  name="edit_functionbox_email_mode" v-model="UpdateOrganisationEmailDTO.email_mode" :options="email_mode">
          </b-form-select>
        </div>

        <h5 v-if="change_from" class="mt-3">{{ $t("EMAILSETTINGS.FROM") }}</h5>
        <div v-if="change_from" class="mt-1">
          <b-form-input trim
            type="text"
            v-model="UpdateOrganisationEmailDTO.email_from"
            name="name"
          ></b-form-input>
          <b-form-text>{{ $t("EMAILSETTINGS.HELP.FROM") }}</b-form-text>
        </div>


        <div v-show="UpdateOrganisationEmailDTO.email_mode == 'SMTP'">

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.FROM_NAME") }}</h5>
          <div class="mt-1">
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_from_name"
              name="name"
            ></b-form-input>
            <b-form-text>{{ $t("EMAILSETTINGS.HELP.FROM_NAME") }}</b-form-text>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.HOSTNAME") }}</h5>
          <div class="mt-1">
            <b-form-input trim
              required
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_hostname"
              name="name"
            ></b-form-input>
            <b-form-text
              >{{ $t("EMAILSETTINGS.HELP.HOSTNAME") }}</b-form-text
            >
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.PORT") }}</h5>
          <div class="mt-1">
            <b-form-input trim
              type="number"
              v-model="UpdateOrganisationEmailDTO.email_port"
              name="name"
            ></b-form-input>
            <b-form-text
              >{{ $t("EMAILSETTINGS.HELP.PORT") }}</b-form-text
            >
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.USERNAME") }}</h5>
          <div class="mt-1">
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_username"
              name="name"
            ></b-form-input>
            <b-form-text
              >{{ $t("EMAILSETTINGS.HELP.USERNAME") }}</b-form-text
            >
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.PASSWORD") }}</h5>
          <div class="mt-1">
              <b-form-input trim
                type="password"
                v-model="UpdateOrganisationEmailDTO.email_password"
                name="UpdateOrganisationEmailDTO.email_password"
              ></b-form-input>
              <b-form-text
                >{{ $t("EMAILSETTINGS.HELP.PASSWORD") }}</b-form-text
              >
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.SECURITY.TITLE") }}</h5>
          <div class="mt-1">
              <b-form-select
                v-model="UpdateOrganisationEmailDTO.email_security"
                :options="email_securitys"
              >
              </b-form-select>
          </div>
        </div>

        <div v-show="UpdateOrganisationEmailDTO.email_mode == 'GRAPH'">

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.TENANT") }}</h5>
          <div class="mt-1">
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_graph_tenant"
              name="name"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.CLIENTID") }}</h5>
          <div class="mt-1">
            <b-form-input trim
              type="text"
              v-model="UpdateOrganisationEmailDTO.email_graph_client_id"
              name="name"
            ></b-form-input>
          </div>

          <h5 class="mt-3">{{ $t("EMAILSETTINGS.CLIENTSECRET") }}</h5>
          <div class="mt-1">
            <b-form-input trim
              type="password"
              v-model="UpdateOrganisationEmailDTO.email_graph_client_secret"
              name="name"
            ></b-form-input>
          </div>

        </div>
      </div>

      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="editBtn">{{
        $t("SAVE")
      }}</b-button>

      <b-button variant="secondary" :disabled="!IsUpdated" class="btn-fill" @click="testBtn">{{
        $t("TEST")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      information: this.organisation,
      loading: false,
      change_password: false,
      change_from: true,
      change_from_name: true,
      email_securitys: [
        { value: 0, text: this.$t("EMAILSETTINGS.SECURITY.0") },
        { value: 2, text: this.$t("EMAILSETTINGS.SECURITY.2") },
        { value: 3, text: this.$t("EMAILSETTINGS.SECURITY.3") },
      ],
      providerOptions: [
        { value: "SEFOS", text: "SEFOS" },
        { value: "CUSTOM", text: this.$t('CUSTOM') },
      ],
      email_mode: [
        { value: "SMTP", text: this.$t("EMAILSETTINGS.SMTP") },
        { value: "GRAPH", text: this.$t("EMAILSETTINGS.MSGRAPH") },
      ],
      UpdateOrganisationEmailDTO: {
        organisation_id: this.organisation.id,
        email_provider: this.organisation.email_provider,
        email_from: this.organisation.email_from,
        email_from_name: this.organisation.email_from_name,
        email_hostname: this.organisation.email_hostname,
        email_port: this.organisation.email_port,
        email_username: this.organisation.email_username,
        email_password: this.organisation.email_password,
        email_security: this.organisation.email_security,
        change_password: this.change_password,
        email_mode: this.organisation.email_mode,
        email_graph_tenant: this.organisation.email_graph_tenant,
        email_graph_client_id: this.organisation.email_graph_client_id,
        email_graph_client_secret: this.organisation.email_graph_client_secret
      }
    };
  },
  methods: {
    editBtn: function() {
      let self = this;
      self.loading = true;
      this.UpdateOrganisationEmailDTO.email_port = parseInt(
        this.UpdateOrganisationEmailDTO.email_port
      );
      this.UpdateOrganisationEmailDTO.change_password = this.change_password;
      self.$http
        .post(
          self.user.hostname + "/organisation/email",
          self.UpdateOrganisationEmailDTO
        )
        .then(function() {
          self.$noty.info(self.$t("UPDATED"));
          self.organisation.email_provider =
            self.UpdateOrganisationEmailDTO.email_provider;
          self.organisation.email_from =
            self.UpdateOrganisationEmailDTO.email_from;
          self.organisation.email_from_name =
            self.UpdateOrganisationEmailDTO.email_from_name;
          self.organisation.email_hostname =
            self.UpdateOrganisationEmailDTO.email_hostname;
          self.organisation.email_port =
            self.UpdateOrganisationEmailDTO.email_port;
          self.organisation.email_username =
            self.UpdateOrganisationEmailDTO.email_username;
          self.organisation.email_password =
            self.UpdateOrganisationEmailDTO.email_password;
          self.organisation.email_security =
            self.UpdateOrganisationEmailDTO.email_security;
          self.organisation.email_mode =
            self.UpdateOrganisationEmailDTO.email_mode;
          self.organisation.email_graph_tenant =
            self.UpdateOrganisationEmailDTO.email_graph_tenant;
          self.organisation.email_graph_client_id =
            self.UpdateOrganisationEmailDTO.email_graph_client_id;
          self.organisation.email_graph_client_secret =
            self.UpdateOrganisationEmailDTO.email_graph_client_secret;
          self.$emit("updatedInformation", self.organisation);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    },
    testBtn: function() {
      let self = this;
      self.loading = true;
      self.$http
        .get(
          self.user.hostname +
            "/organisation/email/test/" +
            self.organisation.id
        )
        .then(function() {
          self.$noty.info(self.$t("SENT"));
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    IsUpdated(){
      return this.UpdateOrganisationEmailDTO.organisation_id == this.organisation.id && 
        this.UpdateOrganisationEmailDTO.email_provider == this.organisation.email_provider && 
        this.UpdateOrganisationEmailDTO.email_from == this.organisation.email_from && 
        this.UpdateOrganisationEmailDTO.email_from_name == this.organisation.email_from_name && 
        this.UpdateOrganisationEmailDTO.email_hostname == this.organisation.email_hostname &&
        this.UpdateOrganisationEmailDTO.email_port == this.organisation.email_port &&
        this.UpdateOrganisationEmailDTO.email_username == this.organisation.email_username &&
        this.UpdateOrganisationEmailDTO.email_password == this.organisation.email_password &&
        this.UpdateOrganisationEmailDTO.email_security == this.organisation.email_security && 
        this.UpdateOrganisationEmailDTO.email_mode == this.organisation.email_mode &&
        this.UpdateOrganisationEmailDTO.email_graph_tenant == this.organisation.email_graph_tenant &&
        this.UpdateOrganisationEmailDTO.email_graph_client_id == this.organisation.email_graph_client_id &&
        this.UpdateOrganisationEmailDTO.email_graph_client_secret == this.organisation.email_graph_client_secret;
    }
  },
  mounted: function() {},
};
</script>
<style></style>
